/* eslint-disable import/no-cycle */
<template>
  <v-container fluid>
    <v-row v-if="hasAuthorityViewLayoutImportMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectLayoutImport">
          Layout Importação
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasAuthorityMovementRulesMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectMovementRules">
          Regras de Movimentação
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasAuthoritySettingDeploymentsFinancialGroupsMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectManagementConfigurationFinancialGroup">
          Configurações e implantações Grupo Financeiro
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasNoAuthorities" align="center" justify="center" class="mt-15">
      <v-col align="center" cols="12">
      <v-row justify="center" class="mt-3">
        <v-col cols="12" md="3" lg="3" align="center">
          <v-icon large color="primary">fas fa-low-vision</v-icon>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-3">
        <v-col cols="12" md="3" lg="3" align="center">
        <p class="text--secondary label">Você não tem permissão para visualizar os menus.</p>
        </v-col>
      </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserUtils from '@/shared/utils/user-utils';

export default ({
  data: () => ({
    hasNoAuthorities: false,
    hasAuthorityViewLayoutImportMenu: false,
    hasAuthorityMovementRulesMenu: false,
    hasAuthoritySettingDeploymentsFinancialGroupsMenu: false,
  }),

  created() {
    this.userUtils = new UserUtils();
  },

  async mounted() {
    sessionStorage.removeItem('financialGroupId');
    this.loadAuthoritiesViewMenus();
    this.verifyAuthorities();
  },

  methods: {
    async loadAuthoritiesViewMenus() {
      this.hasAuthorityViewLayoutImportMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_layout_importacao' });
      this.hasAuthorityMovementRulesMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_regras_movimentacao' });
      this.hasAuthoritySettingDeploymentsFinancialGroupsMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_configuracao_implantacao_grupo_financeiro' });
    },
    verifyAuthorities() {
      if (this.hasAuthorityViewLayoutImportMenu || this.hasAuthorityMovementRulesMenu || this.hasAuthoritySettingDeploymentsFinancialGroupsMenu) {
        this.hasNoAuthorities = false;
      } else {
        this.hasNoAuthorities = true;
      }
    },
    redirectRegrasGerais() {
      this.$router.push('/rule');
    },
    redirectModelosDeLayout() {
      this.$router.push('/layout');
    },
    redirectColetores() {
      this.$router.push('/collectors');
    },
    redirectLayoutImport() {
      this.$router.push('/layout/importacao');
    },
    redirectMovementRules() {
      this.$router.push('/contract-movement');
    },
    redirectElegibility() {
      this.$router.push('/eligibility');
    },
    redirectMovement() {
      this.$router.push('/movement');
    },
    redirectImplantation() {
      this.$router.push('/beneficiary-plan-information?movementType=implantation');
    },
    redirectInclusion() {
      this.$router.push('/beneficiary-plan-information?movementType=insert');
    },
    redirectCommissionExtract() {
      this.$router.push('/commission-extracts');
    },
    redirectAlteration() {
      this.$router.push('/search-holder-dependent');
    },
    redirectDelete() {
      this.$router.push('/search-holder-dependent-delete');
    },
    redirectDependentInclusion() {
      this.$router.push('/dependent-inclusion-search-holders');
    },
    redirectCriticizedMovesHolder() {
      this.$router.push('/criticized-moves-holder');
    },
    redirectCriticizedMovesDependent() {
      this.$router.push('/criticized-moves-dependent');
    },
    redirectReactivation() {
      this.$router.push('/reactivation');
    },
    redirectBatchConfiguration() {
      this.$router.push('/batch-configuration');
    },
    redirectAccompanimentSinisterAndInvoice() {
      this.$router.push('/sinister-invoice');
    },
    redirectManagementConfigurationFinancialGroup() {
      this.$router.push('/management-configuration-financial-group');
    },
    redirectManagementConfigurationByCarrier() {
      this.$router.push('/management-configuration-carrier');
    },
    redirectParametrizationBrokerAndInsuranceCarrier() {
      this.$router.push('/parametrization-broker-carrier');
    },
    redirectProcedureRegister() {
      this.$router.push('/procedure-register-search');
    },
  },
});
</script>
